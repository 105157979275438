import React from "react";
import '../../components/Layout/layout.css';


const BulletPointsAlpha = ({ children }) => {
  return (

    <ul className="bullet-points-alpha">
      <li>
        {children}
      </li>
    </ul>
  );
};

export default BulletPointsAlpha;
